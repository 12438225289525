import "../../landing/css/credit-request.scss";
import "./company-autocomplete";

(function () {

    $(document).on('click', '.wizard-next-btn, .wizard-send-request-btn', function(){
        let form = $(this).closest("#wizardContent").find('form');
        if (form.valid()) {
            form.trigger("submit");
        }
    });
    
    $(document).on('click', '#exitCreditRequestWizard', function(e){
        e.preventDefault();
        $("#exitCreditRequestModal").modal("show");
    });

    $(document).on('click', '#exitCreditRequestConfirmationBtn', function(e){
        window.location.replace("/");
    });

    $(document).on('click', '.credit-request-success-btn', function(e){
        window.location.replace("/");
    });

    // click on calculator button
    $(document).on('click', '.credit-request-calc-btn', function(e){
        let amount = $("#amountSlider").val();
        let dues = $("#duesSlider").val();

        window.location = "solicitud-de-credito/efectivo/informacion-cliente?amount=" + amount + "&dues=" + dues;
    });
})();